import React from "react"
import PropTypes from 'prop-types'
import ImageGallery from "react-image-gallery"

const DialogGallery = ({ images, galleryIndex }) => {
  return (
    <ImageGallery
      items={images}
      showThumbnails={false}
      showFullscreenButton={false}
      startIndex={galleryIndex + 3}
      lazyLoad={false}
      showPlayButton={false}
    />
  )
}

DialogGallery.propTypes = {
  images: PropTypes.array.isRequired,
  galleryIndex: PropTypes.number
}

export default DialogGallery