import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
// components
import GetStarted from "../components/CTA/GetStarted"
import GridGallery from "../components/GridGallery/GridGallery"
import ImageMeta from "../components/ImageMeta"
import Layout from "../components/layout"
import SEO from "../components/seo"
import TheaterVideo from "@components/Theater/TheaterVideo"
import { SharedStateProvider } from "../components/SharedState/PageSharedState"
import MarkdownViewer from "@bit/azheng.joshua-tree.markdown-viewer"
import Seamless from "../components/SeamlessIframe"

const SingleLifestyle = ({
  data,
  pageContext: { language },
  location: { pathname },
}) => {
  const post = data.allLifestylePagesJson.nodes[0]

  const {
    getStarted,
    heading,
    homeHero,
    imageQuoteSection,
    lifestyleGallery,
    metaDescription,
    metaTitle,
    subheadingAndParagraphsSection,
  } = post

  const renderHomeHero = () => {
    const { lifestyleVideoThumbnail, youtube } = homeHero
    return (
      <div className="single-lifestyle-home-hero">
        <TheaterVideo
          buttonClass="contained"
          videoUrl={`https://www.youtube.com/watch?v=${youtube}`}
          overVideo={true}
          language={language}
        >
          <ImageMeta
            className="theater-image"
            cloudName="nuvolum"
            publicId={lifestyleVideoThumbnail}
            width="auto"
          />
        </TheaterVideo>
      </div>
    )
  }

  const renderImageAndQuote = () => {
    const { lifestyleImage, lifestyleQuote } = imageQuoteSection
    return (
      <div className="single-lifestyle-page-image-quote">
        <ImageMeta
          className="single-lifestyle-image"
          cloudName="nuvolum"
          publicId={lifestyleImage}
          width="auto"
        />
        <MarkdownViewer
          className="single-lifestyle-quote"
          markdown={lifestyleQuote}
        />
      </div>
    )
  }

  const renderSubheadingAndParagraphs = () => {
    const { content } = subheadingAndParagraphsSection
    return (
      <MarkdownViewer className="single-lifestyle-content" markdown={content} />
    )
  }

  const renderLifestyleGallery = () => {
    return (
      <GridGallery
        className="single-lifestyle-gallery"
        photos={lifestyleGallery}
        numberOfRows={3}
      />
    )
  }

  const renderGetStarted = () => {
    const { heading: getStartedHeading, buttons, blurb } = getStarted
    return (
      <div className="single-lifestyle-cta color-back">
        <GetStarted
          sideColumnIs={5}
          centerHeading
          centerText
          heading={getStartedHeading}
          buttons={buttons}
          paragraph={<p>{blurb}</p>}
        />
      </div>
    )
  }

  return (
    <SharedStateProvider>
      <Layout language={language}>
        <SEO
          description={metaDescription}
          title={metaTitle}
          pathname={pathname}
        />
        <div className="single-lifestyle-page-container">
          <h1 className="single-lifestyle-page-header has-text-centered">
            {heading}
          </h1>
          {renderHomeHero()}
          <div className="columns">
            <div className="column is-4" />
            <div className="column">
              {renderImageAndQuote()}
              {renderSubheadingAndParagraphs()}
              {renderLifestyleGallery()}
            </div>
            <div className="column is-4" />
          </div>
          <div className="single-lifestyle-cta color-back">
            <GetStarted
              sideColumnIs={5}
              centerHeading
              centerText
              heading={post.consultation.heading}
              buttons={post.consultation.buttons}
              paragraph={<p>{post.consultation.blurb}</p>}
            />
          </div>

          {post.lifestyleForm.hasThisSection && (
            <div className="lifestyle-form">
              <div>
                <div className="columns">
                  <div className="column has-text-centered">
                    <h3>{post.lifestyleForm.heading}</h3>
                  </div>
                </div>
                <div className="columns">
                  <div className="column is-5" />
                  <div className="column has-text-centered">
                    <MarkdownViewer markdown={post.lifestyleForm.blurb} />
                  </div>
                  <div className="column is-5" />
                </div>
              </div>
              <Seamless src="https://secureform.seamlessdocs.com/f/3d54acfda17af36f2246c867c2660451?embedded=true" />
            </div>
          )}

          {renderGetStarted()}
        </div>
      </Layout>
    </SharedStateProvider>
  )
}

export const pageQuery = graphql`
  query SingleLifestylePage($title: String!) {
    allLifestylePagesJson(filter: { title: { eq: $title } }) {
      nodes {
        lifestyleGallery
        metaDescription
        metaTitle
        name
        subheadingAndParagraphsSection {
          content
        }
        heading
        getStarted {
          heading
          blurb
          buttons {
            button {
              buttonText
              href
              destination
              appearance
            }
          }
        }
        imageQuoteSection {
          lifestyleImage
          lifestyleQuote
        }
        title
        homeHero {
          lifestyleVideoThumbnail
          youtube
        }
        consultation {
          heading
          blurb
          buttons {
            button {
              buttonText
              appearance
              href
              destination
            }
          }
        }
        lifestyleForm {
          hasThisSection
          heading
          blurb
        }
      }
    }
  }
`

SingleLifestyle.propTypes = {
  data: PropTypes.object.isRequired,
  location: PropTypes.object,
  pageContext: PropTypes.object,
}

export default SingleLifestyle
