import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Dialog } from "@blueprintjs/core"
// components
import GridRowGallery from './GridRowGallery'
import DialogGallery from './DialogGallery'
import CloseIcon from "../../components/CloseIcon"

const GridGallery = ({ photos, numberOfRows }) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false)
  const [galleryIndex, setGalleryIndex] = useState(0)

  const toggleDialog = index => {
    setIsDialogOpen(!isDialogOpen)
    setGalleryIndex(index)
  }

  const groupsOfPhotos = photos
    .map(function (e, i) {
      return i % numberOfRows === 0 ? photos.slice(i, i + numberOfRows) : null
    })
    .filter(function (e) {
      return e
    })

  const getReactGalleryPhotos = (photos) => {
    const formattedPhotos = []

    for (const currentPhoto of photos) {
      const obj = {}
      obj.original = `https://res.cloudinary.com/nuvolum/image/upload/q_60,w_1024/v1/${currentPhoto}.jpg`
      formattedPhotos.push(obj)
    }

    return formattedPhotos
  }

  const renderRows = () => {
    return groupsOfPhotos.map((row, i) => (
      <GridRowGallery
        toggleDialog={toggleDialog}
        rowIndex={i - 1}
        key={`gallery-row-${i}`}
        items={row}
      />
    ))
  }

  return (
    <>
      {renderRows()}
      <Dialog
        className="community-dialog"
        lazy={false}
        backdropClassName="dark-background"
        portalClassName="show-cursor community-portal"
        isOpen={isDialogOpen}
        onClose={toggleDialog}
      >
        <CloseIcon onClick={toggleDialog} />
        <DialogGallery galleryIndex={galleryIndex} images={getReactGalleryPhotos(photos)} />
      </Dialog>
    </>
  )
}

GridGallery.propTypes = {
  photos: PropTypes.array.isRequired,
  numberOfRows: PropTypes.number
}

export default GridGallery
  
 