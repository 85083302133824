import React from "react"
import PropTypes from "prop-types"
import ImageMeta from "../ImageMeta"

const GridRowGallery = ({ toggleDialog, rowIndex, items }) => {
  return (
    <div className="columns is-desktop community-row">
      {items.map((item, i) => {
        const itemLen = items.length
        if (itemLen === 1) {
          return (
            <>
              <div className={`column gallery-column first`}>
                <ImageMeta className="gallery-image" onClick={() => toggleDialog(i + 3 * rowIndex)} noLazyload
                  cloudName="nuvolum" publicId={item} dpr="auto" aspectRatio="16:9" responsive width="auto" crop="fill"
                  gravity="faces" responsiveUseBreakpoints="true"
                />
              </div>
              <div className={`column gallery-column vis-hidden`} style={{ visibility: "hidden" }}>
                <ImageMeta className="gallery-image" onClick={() => toggleDialog(i + 3 * rowIndex)} noLazyload
                  cloudName="nuvolum" publicId={item} dpr="auto" aspectRatio="16:9" responsive width="auto" crop="fill"
                  gravity="faces" responsiveUseBreakpoints="true"
                />
              </div>
              <div className={`column gallery-column vis-hidden last`} style={{ visibility: "hidden" }}>
                <ImageMeta className="gallery-image" onClick={() => toggleDialog(i + 3 * rowIndex)} noLazyload
                  cloudName="nuvolum" publicId={item} dpr="auto" aspectRatio="16:9" responsive width="auto" crop="fill"
                  gravity="faces" responsiveUseBreakpoints="true"
                />
              </div>
            </>
          )
        } else if (itemLen === 2) {
          return (
            <>
              <div className={`column gallery-column ${i === itemLen - 1 ? "last" : i === 0 ? "first" : ""} ${i === itemLen - 1 ? "last" : i === 0 ? "first" : ""}`}>
                <ImageMeta className="gallery-image" onClick={() => toggleDialog(i + 3 * rowIndex)} noLazyload
                  cloudName="nuvolum" publicId={item} dpr="auto" aspectRatio="16:9" responsive width="auto" crop="fill"
                  gravity="faces" responsiveUseBreakpoints="true"
                />
              </div>
              {i === 1 && (
                <div className={`column gallery-column vis-hidden`} style={{ visibility: "hidden" }}>
                  <ImageMeta className="gallery-image" onClick={() => toggleDialog(i + 3 * rowIndex)} noLazyload
                    cloudName="nuvolum" publicId={item} dpr="auto" aspectRatio="16:9" responsive width="auto" crop="fill"
                    gravity="faces" responsiveUseBreakpoints="true"
                  />
                </div>
              )}
            </>
          )
        }
        return (
          <>
            <div className={`column gallery-column ${i === itemLen - 1 ? "last" : i === 0 ? "first" : ""} ${i === itemLen - 1 ? "last" : i === 0 ? "first" : ""}`}>
              <ImageMeta className="gallery-image" onClick={() => toggleDialog(i + 3 * rowIndex)} noLazyload
                cloudName="nuvolum" publicId={item} dpr="auto" aspectRatio="16:9" responsive width="auto" crop="fill"
                gravity="faces" responsiveUseBreakpoints="true"
              />
            </div>
          </>
        )
      })}
    </div>
  )
}

GridRowGallery.propTypes = {
  toggleDialog: PropTypes.func.isRequired,
  rowIndex: PropTypes.number,
  items: PropTypes.array
}

export default GridRowGallery
